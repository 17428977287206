.filtersContainer {
	display: flex;
	margin-bottom: 10px;
	font-size: 10px;
	input {
		margin-right: 5px;
	}
}

.Container {
	background-color: white;
	@media (min-width: 768px) {
		padding: 1rem;
		border-radius: 0.25rem;
		border: 1px solid #dddbda;
	}
	.Content {
		padding: 0.25rem;
		@media (min-width: 768px) {
			padding: unset;
		}
	}
}
