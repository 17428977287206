.contentContainer {
	height: 100%;
	padding: 0.5rem;
}

.form {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	height: min-content;
}
