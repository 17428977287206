.editor {
	width: 100%;
	height: 100vh;
}

.imageEditorContainer {
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	button {
		padding: 3px;
		margin: 0 5px 10px 5px;
	}
	.controlRow {
		flex-direction: row;
		.numberInput {
			width: 50px;
			padding-left: 0px;
			padding-right: 0px;
			margin-right: 0px;
			margin-left: 0px;
		}
		.rotate {
			border: none;
			padding: 2px;
			padding-top: 0px;
			margin: 10px;
			background-color: rgba(0, 0, 0, 0.336);
		}
	}
	.control {
		max-width: 18px;
	}
}
