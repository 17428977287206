.container {
	padding: 0rem;
	@media (min-width: 768px) {
		padding: 1rem;
	}
}

.grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0rem;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
}

.manualLocations {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
}
