.gridContainer {
	display: grid;
	grid-template-columns: 360px 1rem 1fr;
	background-color: white;
	gap: 1px;
	overflow: hidden;
	[class~="slds-button_icon-x-small"] {
		width: 100%;
	}
}

.fullView {
	display: grid;
	grid-template-columns: 1rem 1fr;
	background-color: white;
	gap: 1px;
	overflow: hidden;
	[class~="slds-button_icon-x-small"] {
		width: 100%;
	}
}
