.assetHeader {
	margin-bottom: 0px;

	@media (min-width: 768px) {
		margin-bottom: 10px;
	}
}

.tabsContainer {
	border-radius: .25rem;
	min-height: calc(100vh - 13rem);
	background-color: white;
	[class~="slds-show"] {
		padding: 0;
	}
	[class~="slds-tabs_scoped__nav"] {
		border-radius: 0px;
		overflow-x: auto;
		overflow-y: hidden;
		padding-right: 1.5rem;
	}
}

.assetGridContainer {
	max-width: 100%;
	@media(min-width: 768px) {
		display: grid;
		grid-template-columns: calc(100vw - 400px) 1fr;
		gap: .5rem;
	}
}

.containerWithSideView {
	max-width: 100%;
	@media(min-width: 768px) {
		display: grid;
		grid-template-columns: calc(100vw - 60px) 1fr;
	}
}

.splitViewContainer {
	max-width: 100%;
}
.rotateList {
	background-color: white;
	list-style: none;
	display: flex;
	width: 20px;
	flex-direction: column;
	margin: 0;
	padding: 0;
}

.rotateListItem {
    font-size: 12px;
    font-weight: 600;
    color: #6b778c;
    text-transform: uppercase;
    cursor: pointer;
	width: 100%;
	:hover {
		background-color: #6b778c57;
	}
}

.rotateInnerContent {
	display: inline-block;
	writing-mode: vertical-lr;
    white-space: nowrap;
	width: 100%;
    padding: 10px 0;
}