.contentContainer {
	display: flex;
	padding: 2rem;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	.inspectionDates {
		label {
			font-weight: bold;
		}
		display: flex;
		justify-content: space-between;
		align-items: center;
		.date {
			padding: 1rem;
		}
	}
}
.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	button {
		width: 30%;
	}
}
