
.searchContainer {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
    [class~='slds-form-element'] {
        width: 100%;
    }
}

