$color-primary: #0078c824;
$color-secondary: #f2f2f2;

$background-color: #ffffff;
.card {
	padding-top: 0.5rem;
	display: flex;
	background-color: $background-color;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 0px 0px 0.5rem 0.5rem;

	height: 100%;
	flex-direction: column;
}

.header {
	font-size: 1.5rem;
	padding: 1rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	gap: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
}

.title {
	font-weight: 600;
}

.peopleGrid {
	display: grid;
	grid-template-columns: 1fr 4fr;
	gap: 0.5rem;
}

.status {
	padding: 0.25rem 0.5rem;
	font-size: 0.75rem;
	font-weight: 600;
	border-radius: 4px;
}

.details {
	padding: 0rem 1rem;
	font-size: 0.875rem;
	color: #4b5563;
}

.toggleButton {
	display: flex;
	align-items: center;
	font-size: 0.875rem;

	color: rgb(11, 11, 11);
	border-radius: 0px 0px 8px 8px;
	background-color: $color-primary;

	width: 100%;
	border: none;
	cursor: pointer;

	padding: 0.5rem;

	&:hover {
		color: #5b5b5b;
	}
}

.icon {
	width: 1rem;
	height: 1rem;
	margin-right: 0.25rem;
}

.comments {
	display: grid;
	background-color: white;
	gap: 0.5rem;
}

.comment {
	background-color: #f3f4f6;
	padding: 0.75rem;
	border-radius: 4px;
}

.commentTitle {
	font-size: 0.875rem;
	font-weight: 600;
	color: #1f2937;
	word-break: break-all;
}

.attachment {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	border-bottom: 1px solid #d1d5db;
}

.commentContent {
	font-size: 0.875rem;
	color: #4b5563;
	word-break: break-all;
}

.commentMeta {
	font-size: 0.75rem;
	color: #6b7280;
	margin-top: 0.25rem;
}

.commentInput {
	width: 100%;
	min-height: 80px;
	padding: 0.5rem;
	border: 1px solid #d1d5db;
	border-radius: 4px;
	font-size: 0.875rem;
	resize: vertical;

	&::placeholder {
		color: #9ca3af;
	}

	&:focus {
		outline: none;
		border-color: #2563eb;
		box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
	}
}

.commentForm {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.name {
	font-size: 0.875rem;
	color: #4b5563;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
}
