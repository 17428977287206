.custom-brand-band {
	background-image: url("banner-brand-default.png"),
		linear-gradient(to top, rgba(245, 245, 245, 0) 0, #1b5f9e);
	height: 20vh;
	padding-top: 0.3rem;
	@media (min-width: 768px) {
		padding: 0.75rem;
	}
	position: absolute;
	width: 100%;
}

[class~="slds-modal__close"] {
	color: white;
}
