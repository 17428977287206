.indicator {
	text-align: center;
	width: 30px;
	padding: 2px 5px;
	display: inline-block;
	vertical-align: middle;
	border-radius: 100%;
}

.AccountCell {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
.Complete {
	background-color: lightgreen;
}

.Pending {
	background-color: lighten(yellow, 20%);
}

.Incomplete {
	background-color: lighten(red, 20%);
}
