.errorCard {
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .account { 
        display: grid;
        gap: 2px;
        font-weight: bolder;
        margin-bottom: 0;
        font-size: 16px;
        span {
            margin-left: 0.5rem;
        }
    }
    .name {
        padding-left: .5rem;
        display: inline-block;
		color: #555;
		font-weight: bold;
		text-transform: capitalize;
    }
}