.staticTextAreaContainer {
	display: grid;
	grid-template-columns: 1fr 0.001fr;
	flex-direction: row;
	min-height: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.158);
	margin-bottom: 1rem;
	.textArea {
		width: 100%;
		white-space: pre-wrap;
	}
	.buttonContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		max-width: min-content;
	}
	.button {
		right: 0.4rem;
		fill: #b0adab;
		color: #b0adab;
	}
	.button:hover {
		color: #0070d2;
	}
}

.isError {
	border: 1px solid #c23934;
	border-radius: 0.25rem;
	margin-bottom: 0;
	padding: .5rem;
}

.errorText {
	color: #c23934;
	font-size: .75rem;
}
