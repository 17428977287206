.form {
	display: flex;
	flex-direction: column;
	.submitButtons {
		position: sticky;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem;
		gap: 0.5rem;
		bottom: 0;
		background-color: white;
		border-top: 1px solid #e5e7eb;
	}
}

.attachmentsContainer {
	padding: 0.5rem;
	border-width: 1px;
	border-style: dashed;
	border-color: #2563eb;
}

.draggedOver {
	border: 1px blue dashed;
}

.notDraggedOver {
	border: 1px rgba(0, 0, 0, 0.25) dashed;
}

.dropContainer {
	height: 100%;
	width: 100%;
	display: flex;
	min-height: 10rem;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	grid-column: span 2;
	border-radius: 0.25rem;
}

.attachments {
	display: grid;
	gap: 0.5rem;
	margin-top: 0.5rem;
	max-height: 10rem;
	overflow-y: scroll;
	.attachmentItem {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem;
		border: 1px solid #e5e7eb;
		border-radius: 0.25rem;
	}
}

.dynamicIcon {
	padding: 0;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
}

.errorContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 0.5rem;

	margin-bottom: 1rem;
	border: 1px solid red;
	border-radius: 0.25rem;
	p {
		color: red;
	}
	gap: 0.5rem;
}
