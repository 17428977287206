.title {
	font-size: large;
}

.email {
	padding-left: 10px;
	font-size: small;
}

.rolesGrid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

.subtitle {
	font-size: large;
	margin-bottom: 10px;
	border-bottom: 1px solid black;
}

.container {
	display: block;
	width: 90vw;
	padding-top: 20px;
	padding-left: 20px;
}
