.conditionReportHeader {
	position: sticky;
	[class~="slds-page-header"] {
		@media (max-width: 768px) {
			border-radius: 0;
		}
	}
	span {
		font-size: 14px;
	}
	ul {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
		@media (min-width: 307px) {
			grid-template-columns: 1fr 1fr;
		}
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
	li {
		[class~="slds-text-title"] {
			font-size: 10px;
		}
		width: 100%;
		max-width: unset;
		padding-left: 0px;
		padding-right: 0px;
		min-height: 30px;
		div {
			white-space: unset;
			width: 100%;
		}
	}
}

.createNew {
	background-color: transparent;
	border: none;
	padding: 0.5rem;
}

.dropDown {
	ul {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0rem;
	}
}
