.skeletonContainer {
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 8px;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 200px);
	margin: 0 auto;
	gap: 0.5rem;
	@media (min-width: 768px) {
		padding: 1rem;
	}
}

.skeletonHeader {
	height: 93.5px;
	padding: 1rem;
	background-color: #e0e0e0;
	border-radius: 4px;
}

.skeletonBody {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.skeletonLine {
	height: 24px;
	width: 100%;
	border-radius: 6px;
	background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
	background-size: 200% 100%;
	animation: placeHolderShimmer 1.5s linear infinite;
}

@keyframes placeHolderShimmer {
	0% {
		background-position: 100% 0;
	}
	100% {
		background-position: -100% 0;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	animation: spin 2s ease-in-out infinite;
}
