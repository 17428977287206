
.OnePerRow {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.TwoPerRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.FourPerRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.SixPerRow {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.EightPerRow {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.CardContainer {
    display: flex;
    flex-direction: column;
}

.container {
    background-color: white;
    border-radius: .25rem;
    height: min-content;
}

.virtualContainer {
    overflow: auto;
    height: 20rem;
    display: grid;
    grid-template-columns: 1fr;
}

.spinnerContainer {
    background-color: white;
    height: 8rem;
}