@media only screen and (max-width: 768px) {
	.container {
		margin: 0;
		padding: 0;
		height: 100vh;
		width: 100vw;
		border-radius: 0;

		header {
			height: 2rem;
			background-color: rgba(30, 30, 30, 0.9);
			border-radius: 0;

			button {
				top: 0;
				right: 0;
			}
		}
	}
	.content {
		border-radius: 0 !important;
	}
}

.content {
	display: grid;
	position: relative;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: 14% 80% 6%;
	align-items: stretch;
	// height: 90vh;
	height: 100%;
	background-color: rgba(30, 30, 30, 0.9);
}

.photosNavPrev {
	background-color: transparent;
	grid-column: 1 / 1;
	grid-row: 2 / 3;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 3rem;
	height: auto;
}

.photosNavNext {
	grid-column: 3 / 3;
	grid-row: 2 / 3;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	min-width: 3rem;
}
.nextButton {
	height: 20%;
	&:focus {
		background-color: transparent;
		color: white;
	}
	&:hover {
		color: white;

		background-color: rgba(100, 100, 100, 0.1);
	}
}

.prevButton {
	height: 20%;
	&:focus {
		background-color: transparent;
		color: white;
	}
	&:hover {
		color: white;
		background-color: rgba(100, 100, 100, 0.1);
	}
}

.photoContainer {
	display: grid;
	grid-auto-columns: 100%;
	grid-auto-rows: 100%;
	place-content: center;
	place-items: center;
	grid-column: 2 / 2;
	grid-row: 2 / 3;
	position: relative;

	.photoWrapper {
		max-width: auto;
	}

	img,
	canvas {
		object-fit: scale-down;
		max-height: 100%;
		max-width: 100%;
	}
}

.videoContainer {
	grid-column: 2 / 2;
	grid-row: 2 / 3;
	display: flex;
	justify-content: center;
	align-content: center;
	background-color: rgba(0,0,0,.25);
	div,
	video {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		height: auto !important;
		max-height: 100%;
		max-width: 100%;
	}
}

.infoContainer {
	grid-column: 1 / 4;
	grid-row: 1 / 1;
	padding: 0.2rem 1em;
}

.tagsContainer {
	padding: 0.5rem;
	display: flex;
	background-color: rgba(50, 50, 50, 0.3);
	border-radius: 3px;
	width: auto;
	height: auto;

	.slds-pill {
		margin-right: 5px;
	}
}
