.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 13rem;
}

.staticPercentWrapper {
	width: 4rem;
}
