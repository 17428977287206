.currencyContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;

	border: 1px solid #dddbda;
	border-radius: 0.25rem;
	padding-left: 0.75rem;
	.currencyInput {
		border: none;
		width: 100%;
		min-height: calc(1.875rem + (1px * 2));
	}
	.currencyInput:focus {
		outline: none;
	}

	.dollarSign {
		color: #3e3e3c;
	}
}

.currencyContainer:focus-within {
	outline: none;
	border: 1px solid #1589ee;
	box-shadow: 0 0 3px #0070d2;
}
