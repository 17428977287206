.sideDrawer-contents {
	padding-top: 40px;
	font-size: 1rem;
}

.SideDrawerRight {
	position: fixed;
	// float: right;
	width: 400px;
	max-width: 90%;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 200;
	background-color: #0075c9;
	padding: 1rem;
	box-sizing: border-box;
	transition: transform 0.3s ease-out;
	color: white;
	transform: translate3d(0, 0, 300px);
	-webkit-transform: translate3d(0, 0, 300px);
	.closeBtnRight {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
	.versionNumber {
		position: absolute;
		bottom: 10px;
	}
}

.Close {
	transform: translateX(-100%);
}

.Open {
	transform: translateX(0);
}

.CloseRight {
	transform: translateX(100%);
}

.header {
	border-bottom: 1px solid white;
	font-size: 1.5rem;
}

.pill {
	background-color: transparent;
	text-align: center;
	vertical-align: end;
	border: 1px solid white;
	border-radius: 0.25rem;
	padding: 0 0.25rem;
}

.settings {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
}

.navButton {
	font-size: 1.25rem;
}
