.smallCardContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
	padding: 1rem;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
