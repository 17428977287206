.container {
	position: relative;
}

.table {
	width: 100%;
	height: 60vh;
	max-height: 600px;
}

.taskTypeCell {
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
	align-items: center;
}

.assetLink {
	height: 100%;
	padding: 0;
	line-height: unset;
	overflow: hidden;
	align-items: flex-start;
	white-space: unset;
	&:hover {
		text-decoration: underline;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	animation: spin 2s ease-in-out infinite;
}
