.container {
	display: grid;
	background-color: white;
	margin-top: 0.5rem;
	place-content: center;
	width: 100%;
	height: 4rem;
	position: sticky;
	left: 0;
	bottom: 0px;
	background: rgba(236, 235, 234, 0.9);
	z-index: 50;
}
