.inputContainer {
    display: flex;
    width: 100%;   
    flex-direction: column;
    min-height: 32px;
}

.container {
    grid-column: 1 / span 2;
	@media (min-width: 767px) {
		grid-column: 1 / span 2;
	}
	@media (min-width: 1200px) {
		grid-column: 1 / span 4;
	}
}

.button {
    display: flex;
    position: relative;
    right: .2rem;
    color: #b0adab
}

.buttonContainer {
    height: 100%;
    min-height: 32px;
    display: flex;
    width: 32px;
    justify-content: center;
    align-items: center;
}
.buttonSearch {
    height: 100%;
}