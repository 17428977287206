.Container {
    background-color: white;
    height: min-content;
    .cards {
        height: calc(100vh - 274px);
        @media(min-width: 768px) {
            height: calc(100vh - 300px)
        }
        display: grid;
        grid-template-columns: 1fr;
    }
    .cardsSideView {
        height: 30rem;
        display: grid;
        grid-template-columns: 1fr;
    }
}