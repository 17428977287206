.formGrid {
	display: grid;
	margin: 0px 0px;
	font-size: 1rem;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(1, 1fr);
	gap: 0.1rem 0.5rem;
	@media (min-width: 767px) {
		grid-template-columns: repeat(4, 1fr);
		margin: 0.1rem 1rem;
	}
	[class~="slds-form-element__label"] {
		color: rgb(175, 175, 175);
	}
}
