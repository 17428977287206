.container {
	background-color: white;
	@media (min-width: 768px) {
		padding: 1rem;
		border-radius: 0.25rem;
		border: 1px solid #dddbda;
	}
	.Content {
		padding: 0.25rem;
		@media (min-width: 768px) {
			padding: unset;
		}
	}
	.cards {
		padding: 0.5rem;
		display: grid;
		gap: 0.5rem;
	}
	.loadMore {
		display: flex;
		justify-content: flex-end;
	}
}

.pagingContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
	margin-top: 1rem;
}
