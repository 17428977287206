.container {
	padding: 0.25rem;
	padding-bottom: 0;
}

.formContainer {
	display: grid;
	gap: 0.5rem;

	.submitButtons {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem;
	}

	.taskItem {
		border: 2px solid aliceblue;
		border-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem;
		@media (min-width: 768px) {
			flex-direction: row;
		}
	}
}
