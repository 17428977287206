.container {
    display: grid;
    gap: .5rem;
    grid-template-columns: 1fr;
    font-size: 1rem;
    @media(min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        
    }

}

.submitButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: .5rem 0;
    margin-top: .5rem;
    position: sticky;
    bottom: 0px;
    background: rgba(236, 235, 234, 0.9);
    z-index: 50;
}
