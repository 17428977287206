.pictureToolContainer {
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	max-height: 100vh;
}

.videoContainer {
	height: 100%;
	max-height: 100vh;
	max-width: 100vw;
}

.photoCapture {
	max-height: 100vh;
	max-width: 100vw;
}

.close {
	position: absolute;
	padding: 0.25rem;
	border-radius: 1rem;
	color: white;
	right: 1rem;
	top: 1rem;
	cursor: pointer;
	height: fit-content;
	z-index: 100001;
	border: none;
	@media (orientation: landscape) {
		right: unset;
		left: 1rem;
	}
	svg {
		height: 1.5rem;
		width: 1.5rem;
	}
	background-color: rgba(0, 0, 0, 0.7);
}

.flash {
	position: fixed;
	padding: 0.25rem;
	border-radius: 1rem;
	color: white;
	left: 1rem;
	top: 1rem;
	cursor: pointer;
	height: fit-content;
	z-index: 100001;
	border: none;
	@media (orientation: landscape) {
		right: unset;
		top: unset;
		left: 1rem;
		bottom: 1rem;
	}
	svg {
		height: 1.5rem;
		width: 1.5rem;
	}
	background-color: rgba(0, 0, 0, 0.7);
}

.submitButtons {
	position: absolute;
	bottom: 0;
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: flex-end;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 0 1rem;
	color: white;
	button {
		border: none;
		width: fit-content;
		height: 3rem;
		padding: 0;
		border-radius: 0.25rem;
		background-color: transparent;
	}
	@media (orientation: portrait) {
		padding: 2rem 0;
	}
}

.shutterButtonContainer {
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	padding: 2rem 0;
	@media (orientation: landscape) {
		left: unset;
		height: 100vh;
		width: fit-content;
		right: 0;
		padding: 0 1rem;
	}

	display: flex;
	justify-content: center;
	align-items: center;

	.shutterButton {
		width: 3rem;
		height: 3rem;
		color: white;
		cursor: pointer;
	}

	.insidePhotoButton {
		width: 2rem;
		height: 2rem;
		color: white;
		cursor: pointer;
	}
}

.container {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100000;
	background-color: black;
	min-width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 0;

	header {
		button {
			top: 1rem;
			right: 1rem;
			position: fixed;
			z-index: 9999;

			@media (orientation: landscape) {
				right: unset;
				left: 1rem;
			}
		}
	}
}

.content {
	border-radius: 0;
}
