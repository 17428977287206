.container {
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1000;
	background-color: white;
	padding: 0.5rem;
	width: 20rem;
	height: 100%;
	border-left: 1px solid #d8dde6;
}

.saveSearchContainer {
	display: flex;
	margin-bottom: 0.5rem;
	width: 100%;
	.nameInput {
		input {
			border-radius: 0.25rem 0px 0px 0.25rem;
			margin: 0px;
			width: 100%;
			min-width: 200px;
			max-width: unset;
		}
	}
	.saveButton {
		border-radius: 0px 0.25rem 0.25rem 0px;
		margin: 0px;
		width: 100%;
	}
}

.filterButtons {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;

	[class~="slds-button"] {
		margin-bottom: 0.25rem;
		margin-left: 0px;
	}
}
