.ToastContainer {
	position: fixed;
	top: 5em;
	left: auto;
	right: 0rem;
	width: fit-content;
	color: white;
	display: flex;
	flex-direction: column;
	max-width: 25rem;
	.Toast {
		max-width: 100%;
		min-width: 10rem;
	}
}

.hiddenButton {
	button {
		display: none;
	}
}
