.bookingHeader {
	position: sticky;
	top: 50px;
	z-index: 5;
	[class~="slds-page-header"] {
		@media (max-width: 768px) {
			border-radius: 0;
		}
	}
	ul {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
		@media (min-width: 307px) {
			grid-template-columns: 1fr 1fr;
		}
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
	li {
		[class~="slds-text-title"] {
			font-size: 10px;
		}
		padding-left: 0px;
		padding-right: 0px;
		min-height: 30px;
		max-width: min-content;
	}
}
