.PhotoSizeSlider {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.712);
	color: white;
	padding: 0.5rem;
	label,
	span {
		font-size: 18px;
		color: white;
	}
	z-index: 1000;
	left: 10vw;
	width: 80%;
	bottom: 0;
}
