.offline {
    color: grey;
    background-color: white;
    svg {
        width: 13px;
        height: 13px;
    }
}

.upload {
    color: white;
    background-color: rgb(2, 98, 146);
    svg {
        width: 13px;
        height: 13px;
    }
}

.refresh {
    fill: white;
    color: white;
    background-color: rgb(214, 122, 3);
    svg {
        stroke-width: 0;
        width: 15px;
        height: 15px;
    }
}
.online {
    color: white;
    background-color: green;
    svg {
        width: 15px;
        height: 8px;
    }
}

.alert {
    background-color: red;
    color: white;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    text-decoration: none;
}