.comments {
	display: grid;
	gap: 0.5rem;
	padding-bottom: 0.5rem;

	.comment {
		width: 100%;
		background-color: #f3f4f6;
		padding: 0.75rem;
		border-radius: 4px;
		h4 {
			font-size: 0.875rem;
			font-weight: 600;
			color: #1f2937;
		}
		.commentUser {
			font-size: 0.75rem;
			font-weight: 600;
			color: #51748b;
		}
	}
}

.commentForm {
	display: grid;
	gap: 0.5rem;
	justify-items: flex-end;
	.commentInput {
		width: 100%;
		min-height: 80px;
		padding: 0.5rem;
		border: 1px solid #d1d5db;
		border-radius: 4px;
		font-size: 0.875rem;
		resize: vertical;

		&::placeholder {
			color: #9ca3af;
		}

		&:focus {
			outline: none;
			border-color: #2563eb;
			box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
		}
	}
}
