.offline {
	width: 100%;
	background-color: rgb(113, 113, 113);
	color: white;
	display: flex;
	top: 3rem;
	left: 0;
	z-index: 90;
	justify-content: center;
}

.syncing {
	width: 100%;
	background-color: rgb(0, 92, 197);
	top: 3rem;
	left: 0;
	color: white;
	display: flex;
	z-index: 90;
	justify-content: center;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	margin-right: 0.25rem;
	animation: spin 2s ease-in-out infinite;
}
