.container {
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: white;
	padding: 0.5rem 2rem;
	img {
		width: 100%;
		max-width: 400px;
	}
}
