.ImagingHeader {
    font-size: small;
    border-radius: 0px;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .75rem;
}