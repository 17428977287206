.container {
	min-height: 60vh;
	[class~="slds-table_header-fixed_container"] {
		max-height: 60vh;
	}

	.pages {
		display: flex;
		justify-content: flex-end;
		padding: 1rem 1rem 0 0;
	}
}

.pricingWorksheetStatus {
	border: 1px solid;
	padding: 2px 4px;
	border-radius: 4px;
	height: fit-content;
	width: fit-content;
	color: #2e844a;
	background-color: white;
	cursor: pointer;
}
