.VisualPicker {
    background: white;
    [class~="slds-form-element__control"] {
        display: grid;
        gap: .5rem;
        grid-template-columns: 1fr;
        @media (min-width: 767px) {
            grid-template-columns: 1fr 1fr;
        }
        width: 100%;
        .Radio {
            display: block;
            min-width: none;
            width: auto;
            padding: 0px;
            margin: 0px;
        }
        span {
            min-width: none;
            max-height: unset;
            padding: 0px;
            width: 100%;
            margin: 0px;
            height: 100%;
        }
        label {
            margin-top: 1rem;
            margin-bottom: 1rem;
            max-height: unset;
            height: fit-content;
        }

        [class~="slds-icon_container"] {
            display: block;
            color: black;
            fill: black;
            width: .5rem;
        }
        [class~="slds-text-title"] {
            padding: 1rem;
            white-space: pre-wrap; 
            text-align: left;
            width: 100%;
            align-self: left;
        }
        [class~="slds-visual-picker__body"] {
            display: none;
        }
    }

}

.VisualPickerWrapper {
    [class~="slds-form-element__legend"] {
        color: red;
    }
}